module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Ultra Top Fuel","short_name":"Ultra Top Fuel","start_url":"/","icon":"static/favicon/favicon-32x32.png","icons":[{"src":"static/favicon/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"static/favicon/android-chrome-512x512.png","sizes":"512x512","type":"image/png"},{"src":"static/favicon/apple-touch-icon.png","sizes":"180x180","type":"image/png"},{"src":"static/favicon/maskable_icon.png","sizes":"196x196","type":"image/png","purpose":"any maskable"}],"background_color":"#1b1c1e","theme_color":"#1b1c1e","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6a1e14762220abc44b6bda7235fa6642"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-0NGQ538NM3"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
